import React from "react"

import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import FancyLink from '../components/elements/FancyLink';

const NotFoundPage = () => (
  <Layout>
  <SEO title="Oops nothing there" />
    <section id="404" className="flex flex-col text-center justify-center px-8 min-h-screen -mt-24" >
      <h1>¯\_(ツ)_/¯</h1>
    <h2 className="my-4">Oops appears that page doesn't exist.</h2>
    <p>Why don't we head back <FancyLink linkTo="/">home</FancyLink> and start again...</p>
    </section>
  </Layout>
)

export default NotFoundPage
