import React from 'react'
import PropTypes from 'prop-types'
import LinkTo from './LinkTo';

const FancyLink = ({children, className="font-bold",  linkTo}) => {
    return (
        <div className={`mx-auto inline-block h-8 border-b-4 border-highlight hover:border-blue-200 transform -rotate-3`}>
        <LinkTo className={`transform inline-block rotate-3 ${className}`} linkTo={linkTo} >{children}   </LinkTo>
        </div>
    )
}

FancyLink.propTypes = {

}

export default FancyLink
